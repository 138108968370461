// This only holds types for now, will add recource in next PR
import { type BasicUserWithPermissions } from './Model/User';
import Role from './Model/Role';
import ActivityType from './Model/ActivityType';
import CustomField, {
	ProductCustomField,
	EntityCustomField,
	CustomFieldWithStages,
	TicketCustomField
} from './Model/CustomField';
import OrderStage from './Model/OrderStage';
import ProductCategory from './Model/ProductCategory';
import Category, { CategoryType } from './Model/Category';
import UserDefinedObjectType from './Model/UserDefinedObjectType';
import { FilterConfig } from 'App/babel/filterConfigs/FilterConfig';
import { ComparisonTypeName } from 'Resources/ComparisonTypes';
import PriceList from './Model/PriceList';
import PaymentExtension from './Model/PaymentExtension';
import { Sort } from 'Resources/RequestBuilder';
import { TicketType } from './Model/Ticket';

export type AccountSelf = {
	id: number;
	name: string;
	email: string;
	version: string;
	features: {
		[key: string]: any;
	};
	client: {
		id: number;
		userId: number;
		name: string;
		numberOfLicenses: number;
		isTrial: boolean;
		contactEmail: string;
		killDate: null | string;
		migrated: 0 | 1;
		movingStarted: null | string;
		provisioningMaxContactTotal: number;
		country: string;
		delayedPayment: null | string;
	};
	clients: {
		clientId: number;
		name: string;
		userId: number;
	}[];
	accountManager: {
		email: string;
		name: string;
		title: string;
		phone: string;
		cellPhone: string;
		sort?: number;
		/* eslint-disable camelcase */
		user_cellPhone?: null | string;
		user_phone?: null | string;
		user_title?: null | string;
		/* eslint-enable camelcase */
	};
	products: {
		[key: string]: boolean;
	};
	unreleasedFeatures: {
		[key: string]: boolean;
	};
	boughtAddons: {
		[key: string]: boolean;
	};
	versionData: {
		availableAddons: string[];
		features: string[];
		name: string;
		parent: string | null;
		products: { crm: boolean; ma: boolean };
		trialAddons: string[];
	};
	availableAddons: string[];
	userActivatedFeatures: { [key: string]: { active: boolean; released?: boolean } };
	lastOnline: null | string;
	lastOnlinePower: null | string;
	language: string;
};

export type CreateRight = 'ALL' | 'OWN' | 'NO' | 'YES';

type CreateRights = {
	Activity: CreateRight;
	Agreement: CreateRight;
	Appointment: CreateRight;
	Client: CreateRight;
	Contact: CreateRight;
	Document: CreateRight;
	Exchange: CreateRight;
	Opportunity: CreateRight;
	Order: CreateRight;
	Project: CreateRight;
	Report: CreateRight;
	SocialEvent: CreateRight;
	UserDefObj1: CreateRight;
	UserDefObj2: CreateRight;
	UserDefObj3: CreateRight;
	UserDefObj4: CreateRight;
};

export type Self = {
	id: number;
	name: string;
	email: string;
	administrator: boolean;
	regDate: string;
	userParams: {
		UserDefinedObject: UserDefinedObjectType[];
		callFromWeb: boolean;
		defaultSignature: number;
		dnbCountryCodes: string;
		easyBooking: boolean;
		emailSuggestions: boolean;
		emailTracking: boolean;
		exchangeSync: boolean;
		keepInTouchReminderActive: boolean;
		keepInTouchReminderMailTemplate: number | null;
		keepInTouchReminderMonths: number;
		keepInTouchReminderProject: number;
		keepInTouchReminderRecipients: number;
		lastDesiredOutcomeDate: string;
		locale: string;
		mailAdmin: boolean;
		multiSoliditetAccess: boolean;
		selectedBrand: number;
		selectedBrandMobile: number;
		signalsModifyAccess: boolean;
		soliditetIsActive: boolean;
		startPage: string;
		subscriptionReminder: boolean;
		subscriptionReminderOnAccountManager: boolean;
		subscriptionReminderOnUser: boolean;
		subscriptionReminderPeriod: number;
		timeZone: string;
		phoneClickDrawer: boolean;
		engagementEmail: boolean;
	};
	language: string;
	clients: Array<{ clientId: number }>;
	role: null | {
		id: number;
		name: string;
	};
	billingAdmin: boolean;
	teamLeader: boolean;
	export: boolean;
	createRights: CreateRights;
	userAddress: string | null;
	userState: string | null;
	userZipCode: string | null;
	userPhone: string | null;
	custom: EntityCustomField[];
	userTitle?: string | null;
	userCellPhone?: string | null;
	crm: boolean;
	support: boolean;
};

export type DocumentTemplate = {
	id: number;
	name: string;
	roles: {
		id: number;
		name: string;
		description: string;
	}[];
	type: string;
};

export type ListViewFilter = {
	filterName: string;
	value: any;
	comparisonType?: ComparisonTypeName;
	inactive?: boolean;
	field?: string | null;
	valueText?: string;
	forceType?: FilterConfig['type'];
};

type ListViewAndReportViewShared = {
	type: string;
	title: string;
	filters: ListViewFilter[];
	default: boolean;
	shared: boolean;
};

// Shared types between standard and user ListView
type ListViewShared = ListViewAndReportViewShared & {
	columns: string[];
	sorting: Sort[];
	limit: number;
	hidden: boolean;
	description: string;
	userlist: { id: string; title: string; isRole: boolean };
	roles: number[];
	users: number[];
};

// Views created by upsales
export type StandardListView = ListViewShared & {
	id: string;
	private: false;
	editable: false;
};

// Views created by users
export type UserListView = ListViewShared & {
	id: number;
	private: boolean;
	editable: boolean;
	regBy: number;
	shared: boolean;
};

type DateFilterValue = { start: Date | null; end: Date | null; preset: string };

export function isSalesboardColumnDateFilter(filter: any): filter is DateFilterValue {
	return !!filter.preset;
}

export type SalesboardViewColumn = {
	id?: number;
	sorting: { attribute: string; ascending: boolean; id: number; sortId: number }[];
	title: string;
	sortOrder: number;
	type: 'order' | 'opportunity' | 'appointment';
	image?: string;
	filters: ListViewFilter[];
};

// SalesboardViews
type SalesboardListViewShared = ListViewAndReportViewShared & {
	type: 'salesboard';
	standard: boolean;
	roles: [];
	users: [];
	filters: ListViewFilter[];
	columns: SalesboardViewColumn[];
	isStandardView: false;
	uuid?: string | null;
	description?: string;
	columnLimit?: number | null;
	regDate?: string;
	modDate?: string;
	regBy?: number;
	sort?: string | null;
	shared?: boolean;
};

export function isSalesboardViewColumn(column: any): column is SalesboardViewColumn {
	return column.type !== 'report';
}

export type SalesboardStandardListView = SalesboardListViewShared & {
	id: string;
	private: false;
	editable: false;
	isStandardView: true;
};
export type SalesboardUserListView = SalesboardListViewShared & {
	id: number | 'standard1';
	private: boolean;
	editable: boolean;
	shared: boolean;
};

export type SalesboardListView = SalesboardUserListView | SalesboardStandardListView;

export type ListView = StandardListView | UserListView;

export function isStandardListView(listView: any): listView is StandardListView {
	return typeof listView.id == 'string' && listView.id.includes('standard');
}

export function isStandardSalesboardListView(listView: any): listView is SalesboardStandardListView {
	return typeof listView.id == 'string' && listView.id.includes('standard');
}

export function isSalesboardUserListView(listView: any): listView is SalesboardUserListView {
	return listView.shared;
}

export function isUserListView(listView: any): listView is UserListView {
	return listView.regBy !== undefined;
}

export function isSalesboardListView(listView?: any): listView is SalesboardListView {
	return listView?.type === 'salesboard';
}

export type ReportView = {
	type: string;
	filters: ListViewFilter[];
	columns: string[];
	default: boolean;
	description: string;
	editable: boolean;
	grouping: string;
	id: string;
	private: boolean;
	sorting: string[];
	tableGrouping: string | null;
	title: string;
	shared?: boolean;
	public?: boolean;
};

export type StandardFieldConfig = {
	name: string;
	tooltip: null | string;
	id: number;
	active: boolean;
	group: string;
	canHide: boolean;
	nameTag: string;
	field: string;
	required: boolean;
	type?: string;
	fieldNameTranslations?: {
		[key: string]: {
			value: string;
		};
	};
	selectOptions?: string | string[];
	disabled: boolean;
	editable: boolean;
	sortOrder: number;
	canMakeRequired: boolean;
	descTag?: string;
	icon?: {
		class: string;
		name?: string;
	};
	elevioId?: number;
};

export type IntegrationConfig = {
	color: string;
	id: number;
	imageLink: null | string;
	inits: string[];
	name: string;
	userConfigurable: boolean;
	userOnly: boolean;
};

export type IntegrationInit = {
	public: boolean;
	alias: null | string;
	capabilities?: {
		typeahead?: {
			idField: string;
			columns: any;
			countryTabs: { enabled: boolean; showAll: boolean };
			pagination: { enabled: boolean };
			waitTime: number;
		};
		countries: string[];
		match: { upsales: 'dunsNo'; integration: string };
		pricing: { buy: { cost: number; endpoint: 'buy' } };
		logo: string;
		refresh?: boolean;
	};
	color: string;
	id: number;
	imageLink: null | string;
	name: string;
	userConfigurable: boolean;
};

export type UiElementConfig = {
	integrationId: number;
	name: string;
};

type OnboardingStep = {
	id: number;
	title: string;
	shadowTitle: string;
	substepTitle: null | string;
	description: string;
	substepDescription: null | string;
	videoLink: string;
	videoThumbnail: string;
	articleTitle: string;
	articleLink: string;
	hasSubSteps: boolean;
	stepType: string;
	sortId: number;
	parentId: number;
	substeps?: OnboardingStep[];
};

export type Currency = {
	masterCurrency: boolean;
	iso: string;
	active: boolean;
	rate: number;
};

export type SalesModelType = 'sales' | 'cm' | 'rr';
export type SalesModelOptionType = 'cm' | 'sales' | 'arr' | 'mrr';
export type SalesModelOption3Type = 'onlyCM' | 'combinedWithCM' | 'onlyRR' | 'cmCombinedWithARR' | 'cmCombinedWithMRR';

export type Metadata = {
	customerCurrencies: Currency[];
	defaultCurrency: Currency;
	params: {
		UserDefinedObject: UserDefinedObjectType[];
		sessionTime: number;
		teamAccountManager: boolean;
		clientOrderRelation: string;
		SalesModel: SalesModelType;
		SalesModelOption: SalesModelOptionType;
		SalesModelOption2?: string;
		SalesModelOption3?: SalesModelOption3Type;
		GroupARRChangesByUser: boolean;
		UseDiscount: boolean;
		WeeklyCommit: boolean;
		AgreementEnabled: boolean;
		SubscriptionNoticePeriod: number[];
		SubscriptionDefaultNoticePeriod: number;
		TicketPriority: boolean;
		TicketMatching: boolean;
		LookerDashboardExeptionIds: string;
		EnableAutoFillAppointmentsDesc: boolean;
		[key: string]: any;
	};
	credits: {
		email: number;
		sms?: number;
		bisnode: number;
		advertising: number;
	};
	map: { mail: boolean; mailActivated: boolean };
	user: { id: number; userCellPhone: string; userPhone: string; userTitle: string };
	role: {
		defaultCurrency: string;
		description: string;
		id: number;
		name: string;
		parentId: number;
		template: number;
	};
	activatedFeatures: {
		easyBooking: {
			bookingUrl: string;
			active: boolean;
			bookingPages: {
				title: string;
				id: number;
				bookingUrl: string;
				active: boolean;
				isShared: boolean;
			}[];
		};
	};
	standardFields: { [key: string]: { [key: string]: StandardFieldConfig } };
	requiredFields: { [key: string]: { [key: string]: boolean } };
	integrations: {
		userConfigurable: IntegrationConfig[];
		active: IntegrationConfig[];
		inits: {
			phone?: IntegrationInit[];
			erp?: IntegrationInit[];
			mail?: IntegrationInit[];
			esign?: IntegrationInit[];
			sms?: IntegrationInit[];
			webinar?: IntegrationInit[];
			fileStorage?: IntegrationInit[];
			calendar?: IntegrationInit[];
			job?: IntegrationInit[];
			chatNotifications?: IntegrationInit[];
			dataSource?: IntegrationInit[];
			// eslint-disable-next-line camelcase
			app_status_order?: IntegrationInit[];
		};
		uiElements: {
			editActivity?: { sidebar: UiElementConfig[] };
			editAppointment?: { sidebar: UiElementConfig[] };
			editOrder?: { sidebar: UiElementConfig[] };
			editSubscription?: { sidebar: UiElementConfig[] };
			account?: { [k in string]: UiElementConfig[] };
			modals?: { name: string }[];
		};
	};
	licenses: number;
	supportLicenses: number;
	soliditetCredits: number;
	metaChannel: string;
	notificationChannel: string;
	notificationUserChannel: string;
	publicChannel: string;
	iOSInterest: string;
	publicUrlHash: string;
	mailEditorHash: string;
	iCalExternalUrlHash: string;
	esign: { active: boolean };
	mainApps: [];
	onboarding: {
		id: number;
		name: string;
		rolePath: string;
		regDate: string;
		modDate: string;
		steps: OnboardingStep[];
		onboardingCompletedId: null;
	}[];
	validFileExtensions: string;
	listOnboarding: {
		listIntro: {
			activity: boolean;
			agreement: boolean;
			appointment: boolean;
			client: boolean;
			contact: boolean;
			email: boolean;
			esign: boolean;
			flow: boolean;
			form: boolean;
			groupMail: boolean;
			lead: boolean;
			mailTemplate: boolean;
			opportunity: boolean;
			order: boolean;
			phoneCall: boolean;
			project: boolean;
			salesboard: boolean;
			segment: boolean;
			visit: boolean;
		};
		track: null;
	};
	userQuotaPeriods: {
		contributionMargin: string;
		oneOff: string;
		recurring: string;
		sales: string;
		// eslint-disable-next-line camelcase
		booked_appointment: string;
	};
	userSurveyResult: UserSurveyResult[] | null | undefined;
};

export type UserSurveyResult = {
	userId: number;
	role: string | number | undefined;
	roleTitle: string;
	otherRole: string | undefined;
	goals: string;
	goalsDescription: string | undefined;
	userExperience: string;
	review: number;
	lowReview: string;
	lowReviewDescription: string;
	stageId: number;
};

export type ObjectReportViews = {
	own: ReportView[];
	public: ReportView[];
	shared?: ReportView[];
};

export type Brand = {
	id: number;
	name: string;
	logo?: string;
	active: boolean;
};

export type AllIWantData = {
	customerSelf: Self;
	self: AccountSelf;
	accessRights: { Agreement: boolean; SocialEvent: boolean };
	paymentExtensions?: PaymentExtension[];
	totals: {
		accounts: number;
		activeCallList: number;
		activeCampaigns: number;
		activeContacts: number;
		activeProducts: number;
		campaigns: number;
		contacts: number;
		products: number;
		oldActivities: number;
	};
	userMap: {
		[key: string]: BasicUserWithPermissions[];
	};
	roleMap: {
		[key: string]: Role[];
	};
	brands: Brand[];
	todoTypes: {
		PHONE_CALL: ActivityType;
		TODO: ActivityType;
	};
	customFields: {
		order: CustomFieldWithStages[];
		orderrow: CustomFieldWithStages[];
		opportunity: CustomFieldWithStages[];
		account: CustomField[];
		agreement: CustomField[];
		activity: CustomField[];
		todo: CustomField[];
		appointment: CustomField[];
		contact: CustomField[];
		product: ProductCustomField[];
		project: CustomField[];
		projectPlan: CustomField[];
		lead: CustomField[];
		ticket: TicketCustomField[];
		user: CustomField[];
		userDefined1: CustomField[];
		userDefined2: CustomField[];
		userDefined3: CustomField[];
		userDefined4: CustomField[];
	};
	documentTemplates: {
		activity: DocumentTemplate[];
		agreement: DocumentTemplate[];
		client: DocumentTemplate[];
		object1: DocumentTemplate[];
		object2: DocumentTemplate[];
		object3: DocumentTemplate[];
		object4: DocumentTemplate[];
		order: DocumentTemplate[];
	};
	userDefinedCategoryTypes: {
		[key: string]: CategoryType[];
	};
	clientCategoryTypes: CategoryType[];
	contactCategoryTypes: CategoryType[];
	userDefinedCategories: {
		[key: string]: Category[];
	};
	listViews: {
		salesboard: SalesboardListView[];
		form: ListView[];
		page: ListView[];
		advancedSearchAccount: ListView[];
		advancedSearchContact: ListView[];
		advancedSearchActivity: ListView[];
		advancedSearchAppointment: ListView[];
		advancedSearchOpportunity: ListView[];
		advancedSearchOrder: ListView[];
		order: ListView[];
		agreement: ListView[];
		phoneCall: ListView[];
		visitor: ListView[];
		formSubmit: ListView[];
		mailCampaign: ListView[];
		account: ListView[];
		contact: ListView[];
		mail: ListView[];
		mailTemplate: ListView[];
		activity: ListView[];
		opportunity: ListView[];
		campaign: ListView[];
		callList: ListView[];
		leads2: ListView[];
		segment: ListView[];
		flow: ListView[];
		projectPlan: ListView[];
		ticket: ListView[];
		[key: string]: (SalesboardListView | ListView)[];
	};
	metadata: Metadata;
	reportViews: {
		[key: string]: ReportView[] | ObjectReportViews;
	};
	hasSharedViews: boolean;
	orderStages: OrderStage[];
	productCategories: ProductCategory[];
	clientCategories: Category[];
	contactCategories: Category[];
	activityTypes: ActivityType[];
	appointmentTypes: ActivityType[];
	acceptTerms: {
		show: boolean;
		accepted: boolean;
		termsId: number | null;
		termsLink: string;
		termsDesc: string;
	};
	priceLists: PriceList[];
	customerSupportForwardEmail: string;
	ticketTypes: TicketType[];
};
