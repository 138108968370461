import moment from 'moment';
import { Action, Actions, State } from './SubscriptionIndexingActions';
import RequestBuilder, { BuildFilters } from 'Resources/RequestBuilder';
import { IndexConflictDecision, PersistedIndexIncreaseSettings } from 'App/resources/Model/IndexIncreaseSettings';

const sdf = 'YYYY-MM-DD';

export const initialState: State = {
	accountFilter: {
		enabled: true,
		type: 'include',
		values: []
	},
	subscriptionFilter: {
		enabled: false,
		type: 'include',
		values: []
	},
	uiFilters: {},
	excludedSubscriptionIds: [],
	specificDate: moment().add(1, 'day').format(sdf),
	indexConflictDecisionsMap: {},
	indexConflictDecisions: [],
	isSpecificRecipient: false,
	setAsLocked: true,
	shouldCreateDiff: false,
	indexingEnabled: false,
	indexInterval: 'once',
	isSpecificDate: true,
	subscriptionNote: '',
	subscriptionCount: 0,
	subscriptionValue: 0,
	indexPercentage: 2,
	specificUser: null,
	lastRunDate: null,
	subscriptions: [],
	isSaving: false,
	isLocked: false,
	isDirty: false,
	filterQ: [],
	filter: null,
	orderNote: '',
	name: '',
	isPassed: false,
	totalIncreaseARR: 0
};

export const getInitialState = (settings?: PersistedIndexIncreaseSettings, isCopy?: boolean) => {
	const state = { ...initialState, ...settings };
	const id = isCopy ? undefined : state.id;
	const isLocked = isCopy ? false : state.isLocked;
	const name = isCopy ? `${state.name} (copy)` : state.name;
	const setAsLocked = isCopy ? true : false;
	const isPassed = state.indexInterval === 'once' && moment(state.specificDate).isSameOrBefore(moment(), 'day');
	// map the decisions to make them easier to use
	const indexConflictDecisionsMap: { [key: number]: IndexConflictDecision } = {};
	state.indexConflictDecisions?.forEach((conflict: IndexConflictDecision) => {
		indexConflictDecisionsMap[conflict.agreementId] = conflict;
	});

	return { ...state, id, name, isPassed, isLocked, setAsLocked, indexConflictDecisionsMap };
};

/** @deprecated */
export const getStaticFilter = (specificDate: State['specificDate'], indexInterval?: State['indexInterval']) => {
	const date = moment(indexInterval === 'renewal' ? undefined : specificDate).format('YYYY-MM-DD');

	const activeVersionIsNull = { a: 'metadata.activeVersionId', c: 'eq', v: null };
	const startDateHasPassedIsh = { a: 'metadata.agreementStartdate', c: 'lte', v: date };
	const endDateIsNull = { a: 'metadata.agreementEnddate', c: 'eq', v: null };
	const endDateInFuture = { a: 'metadata.agreementEnddate', c: 'gte', v: date };

	return {
		or: {
			q: [
				[activeVersionIsNull, startDateHasPassedIsh, endDateIsNull],
				[activeVersionIsNull, startDateHasPassedIsh, endDateInFuture]
			]
		}
	};
};

export const getFilter = (
	filterQ: State['filterQ'],
	excludedSubscriptionIds: number[],
	indexInterval: State['indexInterval']
): BuildFilters => {
	const q: any[] = [];
	const queryBuilder = new RequestBuilder();
	q.push(...(filterQ || []));
	q.push({ a: 'metadata.orderSequenceNr', c: 'gt', v: 0 }); // has started
	q.push({ a: 'yearlyValue', c: 'gt', v: 0 });

	/**
	 * If we don't include agreements with end date in the future, it may happen that
	 * we miss agreements that have a planned period because the current agreement is set
	 * as it will not create more orders. Another case is that we want to create a prorated order
	 * of the last order of the agreement.
	 */
	const orBuilder = queryBuilder.orBuilder();
	orBuilder.addFilter({ field: 'metadata.willCreateMoreOrders' }, 'eq', true);
	orBuilder.next();
	orBuilder.addFilter({ field: 'metadata.agreementEnddate' }, 'gt', moment().format(sdf));
	orBuilder.done();
	q.push({ or: { q: orBuilder.build().q } });

	// if indexing at renewal, we don't want to include agreements with periodLength = 0
	if (indexInterval === 'renewal') {
		q.push({
			a: 'metadata.periodLength',
			c: 'ne',
			v: [0]
		});
	}

	if (excludedSubscriptionIds.length) {
		q.push({
			a: 'id',
			c: 'ne',
			v: excludedSubscriptionIds
		});
	}

	return { q };
};

/**
 *
 * @deprecated Only for the old version of index increase settings
 */
const getFilterOld = (
	specificDate: State['specificDate'],
	accountFilter: State['accountFilter'],
	subscriptionFilter: State['subscriptionFilter']
): BuildFilters => {
	const q: any[] = [];

	q.push(getStaticFilter(specificDate));

	if (accountFilter.enabled) {
		type GroupType = {
			accountIds: number[];
			projectIds: number[];
		};
		const groups = accountFilter.values.reduce(
			(acc, curr) => {
				const key = curr.type === 'project' ? 'projectIds' : 'accountIds';
				acc[key].push(curr.id);
				return acc;
			},
			{ accountIds: [], projectIds: [] } as GroupType
		);

		const isInclude = accountFilter.type === 'include';

		const anotherQ = {
			or: {
				q: [] as any
			}
		};

		if (groups.accountIds.length) {
			const clientFilter = {
				a: 'client.id',
				c: isInclude ? 'eq' : 'ne',
				v: groups.accountIds
			};

			if (isInclude) {
				anotherQ.or.q.push([clientFilter]);
			} else {
				q.push(clientFilter);
			}
		}
		if (groups.projectIds.length) {
			const projectFilter = {
				a: 'client.project.id',
				c: isInclude ? 'eq' : 'ne',
				v: groups.projectIds
			};

			if (isInclude) {
				anotherQ.or.q.push([projectFilter]);
			} else {
				q.push(projectFilter);
			}
		}
		if (!groups.accountIds.length && !groups.projectIds.length) {
			q.push({
				a: 'client.id',
				c: subscriptionFilter.type === 'include' ? 'eq' : 'ne',
				v: [-1]
			});
		}

		if (isInclude) {
			q.push(anotherQ);
		}
	}

	if (subscriptionFilter.enabled) {
		q.push({
			a: 'id',
			c: subscriptionFilter.type === 'include' ? 'eq' : 'ne',
			v: subscriptionFilter.values?.length ? subscriptionFilter.values.map(subscription => subscription.id) : [-1]
		});
	}

	return {
		q: q,
		offset: 0,
		limit: 1000,
		sort: [
			{
				a: 'client.name',
				s: 'A'
			},
			{
				a: 'description',
				s: 'A'
			}
		]
	};
};

export const reducer = (state: State, action: Action): State => {
	const { type } = action;

	switch (type) {
		case Actions.SET_STATE:
			return {
				...state,
				...action.payload
			};
		case Actions.SET_FILTER_Q: {
			if (state.isLocked) {
				return state;
			}

			const filter = getFilter(action.payload, state.excludedSubscriptionIds, state.indexInterval);

			return {
				...state,
				filterQ: action.payload,
				filter
			};
		}
		case Actions.SET_NAME:
			return {
				...state,
				name: action.payload
			};
		case Actions.SET_SUBSCRIPTIONS:
			return {
				...state,
				subscriptions: action.payload
			};
		case Actions.SET_AS_LOCKED:
			return {
				...state,
				setAsLocked: action.payload,
				isDirty: true
			};
		case Actions.INCLUDE_SUBSCRIPTION_ID: {
			const excludedSubscriptionIds = state.excludedSubscriptionIds.filter(x => x !== action.payload);
			const filter = getFilter(state.filterQ, excludedSubscriptionIds, state.indexInterval);

			return {
				...state,
				filter,
				subscriptionCount: state.subscriptionCount ? state.subscriptionCount + 1 : 1,
				excludedSubscriptionIds
			};
		}
		case Actions.EXCLUDE_SUBSCRIPTION_ID: {
			const excludedSubscriptionIds = [...state.excludedSubscriptionIds, action.payload];
			const filter = getFilter(state.filterQ, excludedSubscriptionIds, state.indexInterval);

			return {
				...state,
				filter,
				subscriptionCount: state.subscriptionCount ? state.subscriptionCount - 1 : 0,
				excludedSubscriptionIds
			};
		}
		case Actions.SET_UI_FILTER:
			if (state.isLocked) {
				return state;
			}

			return {
				...state,
				uiFilters: action.payload
			};
		case Actions.TOGGLE_LOCK: {
			const filter = getFilter(state.filterQ, state.excludedSubscriptionIds, state.indexInterval);

			return {
				...state,
				filter,
				isLocked: action.payload,
				isDirty: true
			};
		}
		case Actions.TOGGLE_LOCK_OLD: {
			const filter = action.payload
				? state.filter
				: getFilterOld(state.specificDate, state.accountFilter, state.subscriptionFilter);

			return {
				...state,
				isLocked: action.payload,
				filter, // Update filter
				isDirty: true
			};
		}
		case Actions.SET_ACCOUNT_FILTER: {
			const filter = state.isLocked
				? state.filter
				: getFilterOld(state.specificDate, action.payload, state.subscriptionFilter);

			return {
				...state,
				filter,
				accountFilter: { ...action.payload },
				isDirty: state.isDirty || action.payload !== state.accountFilter
			};
		}
		case Actions.SET_SUBSCRIPTION_COUNT:
			return {
				...state,
				subscriptionCount: action.payload
			};
		case Actions.SET_SUBSCRIPTION_VALUE:
			return {
				...state,
				subscriptionValue: action.payload
			};
		case Actions.SET_SUBSCRIPTION_FILTER: {
			const filter = state.isLocked
				? state.filter
				: getFilterOld(state.specificDate, state.accountFilter, action.payload);

			return {
				...state,
				filter,
				subscriptionFilter: { ...action.payload },
				isDirty: state.isDirty || action.payload !== state.subscriptionFilter
			};
		}
		case Actions.TOGGLE_SPECIFIC_DATE:
			return {
				...state,
				isSpecificDate: action.payload,
				isDirty: state.isDirty || action.payload !== state.isSpecificDate
			};
		case Actions.SET_INDEX_PERCENTAGE:
			return {
				...state,
				indexPercentage: action.payload,
				isDirty: state.isDirty || action.payload !== state.indexPercentage
			};
		case Actions.SET_INDEX_INTERVAL: {
			const filter = getFilter(state.filterQ, state.excludedSubscriptionIds, action.payload);

			return {
				...state,
				filter,
				isSpecificDate: action.payload === 'once' ? true : state.isSpecificDate,
				indexInterval: action.payload,
				isDirty: state.isDirty || action.payload !== state.indexInterval
			};
		}
		case Actions.SET_ORDER_NOTE:
			return {
				...state,
				orderNote: action.payload,
				isDirty: state.isDirty || action.payload !== state.orderNote
			};
		case Actions.SET_SHOULD_CREATE_DIFF:
			return {
				...state,
				shouldCreateDiff: action.payload,
				isDirty: state.isDirty || action.payload !== state.shouldCreateDiff
			};
		case Actions.SET_SPECIFIC_DATE:
			return {
				...state,
				specificDate: action.payload,
				isDirty: state.isDirty || action.payload !== state.specificDate
			};
		case Actions.SET_SPECIFIC_DATE_OLD: {
			const filter = state.isLocked
				? state.filter
				: getFilterOld(action.payload, state.accountFilter, state.subscriptionFilter);

			return {
				...state,
				filter,
				specificDate: action.payload,
				isDirty: state.isDirty || action.payload !== state.specificDate
			};
		}
		case Actions.SET_SPECIFIC_USER:
			return {
				...state,
				specificUser: action.payload,
				isDirty: state.isDirty || action.payload !== state.specificUser
			};
		case Actions.SET_SUBSCRIPTION_NOTE:
			return {
				...state,
				subscriptionNote: action.payload,
				isDirty: state.isDirty || action.payload !== state.subscriptionNote
			};
		case Actions.TOGGLE_SPECIFIC_RECIPIENT:
			return {
				...state,
				isSpecificRecipient: action.payload,
				isDirty: state.isDirty || action.payload !== state.isSpecificRecipient
			};
		case Actions.TOGGLE_INDEXING_ENABLED:
			return {
				...state,
				indexingEnabled: action.payload,
				isDirty: state.isDirty || action.payload !== state.indexingEnabled
			};
		case Actions.TOGGLE_DIRTY:
			return {
				...state,
				isDirty: action.payload
			};
		case Actions.TOGGLE_SAVING:
			return {
				...state,
				isSaving: action.payload,
				isDirty: false
			};
		case Actions.SET_CONFLICT_DECISION: {
			const newMap = { ...state.indexConflictDecisionsMap, [action.payload.agreementId]: action.payload };
			return {
				...state,
				indexConflictDecisionsMap: newMap
			};
		}
		case Actions.SET_CONFLICTING_SUBSCRIPTIONS: {
			const newMap: State['indexConflictDecisionsMap'] = {};
			Object.values(action.payload).forEach(conflictDecision => {
				newMap[conflictDecision.agreementId] =
					state.indexConflictDecisionsMap[conflictDecision.agreementId] ?? conflictDecision;
			});
			return {
				...state,
				indexConflictDecisionsMap: newMap
			};
		}
		default:
			return state;
	}
};
