import {
	ButtonSelect,
	DrawerHeader,
	AssistChip,
	Drawer,
	Loader,
	Title,
	Block,
	Text,
	Flex,
	Link,
	Tabs,
	Icon,
	Row,
	Tab,
	Avatar,
	DropDownMenu,
	ClickableItem,
	EllipsisTooltip,
	Tooltip
} from '@upsales/components';
import MailAttachment from 'Components/MailDrawers/Components/MailAttachment';
import MentionsInput from 'Components/Mentions/MentionsInput/MentionsInput';
import { PrimaryButton, DefaultButton, ThirdButton } from '@upsales/components/Buttons';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import ClosedTicketScreen from './ClosedTicketScreen/ClosedTicketScreen';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'Components/Helpers/translate';
import { getAccountSelf } from 'Store/selectors/AppSelectors';
import { SlideFade } from '@upsales/components/animations';
import { useEditTicketContext } from '../Context/Context';
import BemClass from '@upsales/components/Utils/bemClass';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';
import { useFeatureAvailable, useIsFirstRender } from 'App/components/hooks';
import { getSelf } from 'Store/selectors/AppSelectors';
import { dateCalendar } from 'App/helpers/DateHelpers';
import type Ticket from 'App/resources/Model/Ticket';
import Comment from 'App/resources/Model/Comment';
import logError from 'App/babel/helpers/logError';
import CommentResource from 'Resources/Comment';
import MailEditor from '../Common/MailEditor';
import Editor from '@draft-js-plugins/editor';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import TicketInfo from './TicketInfo';
import './EditTicket.scss';
import FormObserver, { FieldModel, getCustomFieldModel } from 'App/components/FormObserver';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import {
	type State,
	type TicketForm,
	mapFormToTicket,
	mapTicketToForm,
	formatErrorMessages,
	getMainRecipient
} from '../Context/Helpers';
import openModal from 'App/services/Modal';
import { FormInput } from 'App/components/FormComponent';

type Props = { ticketId: number } & ModalProps;

const poorMansUpsales = `
	body{
		margin: 0;
		font-family: "Roboto", sans-serif;
		font-size: 14px;
		font-weight: 400;
		height: fit-content;
		line-height: 1.428;
	}
	a{
		color: #3F76BE;
	}
`;

const IframeWrapper = ({ srcDoc }: { srcDoc: string }) => {
	return (
		<iframe
			onLoad={e => {
				const iframe = e.target as HTMLIFrameElement;
				if (!iframe.contentWindow) {
					return;
				}

				iframe.style.height =
					iframe.contentWindow.document.documentElement.getBoundingClientRect().height + 'px';
				const linkTag = iframe.contentWindow.document.createElement('link');
				linkTag.id = 'custom-link';
				linkTag.href = 'https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i';
				linkTag.rel = 'stylesheet';
				iframe.contentWindow.document.head.appendChild(linkTag);

				const style = document.createElement('style');
				style.textContent = poorMansUpsales;
				iframe.contentWindow.document.head.appendChild(style);

				const anchors = iframe.contentWindow.document.getElementsByTagName('a');
				for (const anchor of anchors) {
					anchor.target = '_blank';
				}
			}}
			srcDoc={srcDoc}
		/>
	);
};

const EditTicket = ({ className, close, modalId, ticketId }: Props) => {
	const {
		state: {
			files,
			ticket,
			events,
			filter,
			saving,
			comment,
			loading,
			hasMoreEvents,
			savingComment,
			publicMessage,
			isValid,
			errorMessages,
			customFields,
			formStateResetTrigger,
			hasUnsavedChanges,
			ticketResponseTemplate
		},
		init,
		addEvent,
		removeFile,
		getEvents,
		setFilter,
		setComment,
		markAsOpen,
		addComment,
		markAsSolved,
		getMoreEvents,
		setUserAndSave,
		onTicketChange,
		deleteTicket,
		setTicket,
		setTicketResponseTemplate
	} = useEditTicketContext();
	const { title, contact, client, id, status, isArchived } = ticket ?? {};
	const email = getMainRecipient(ticket.involved)?.email;

	const [showSaveBanner, setShowSaveBanner] = useState(false);
	const isFirstRender = useIsFirstRender();
	const saveBannerTimeoutRef = useRef<null | NodeJS.Timeout>(null);
	const hasCustomerSupport = useFeatureAvailable('CUSTOMER_SUPPORT');

	const [fileNames, setFileNames] = useState<{ [key: string]: number }>({});

	const validationModel = {
		contactInfo: FieldModel.object('contactInfo', {
			client: FieldModel.object('default.client', {
				id: FieldModel.number('default.id').required(),
				name: FieldModel.string('default.name').required()
			}),
			contact: FieldModel.object('contactInfo.contact', {
				id: FieldModel.number('default.id').required(),
				name: FieldModel.string('default.name').required(),
				email: FieldModel.email('default.email').required()
			}),
			email: FieldModel.email('default.email').required()
		}),
		title: FieldModel.string('ticket.title').required(),
		custom: getCustomFieldModel(customFields)
	};

	const initialValues = mapTicketToForm(ticket, customFields);

	useEffect(() => {
		init(ticketId);
	}, [ticketId]);

	useEffect(() => {
		if (!ticket?.id) {
			return;
		}
		getEvents();
	}, [filter, ticket?.id]);

	useEffect(() => {
		if (!saving && !isFirstRender) {
			if (saveBannerTimeoutRef.current) {
				clearTimeout(saveBannerTimeoutRef.current);
			}

			setShowSaveBanner(true);
			saveBannerTimeoutRef.current = setTimeout(() => {
				setShowSaveBanner(false);
				saveBannerTimeoutRef.current = null;
			}, 3000);
		}
	}, [saving]);

	const [replyMode, setReplyMode] = useState<'public' | 'internal'>('public');
	const textareaRef = useRef<HTMLTextAreaElement | Editor | null>(null);
	const titleInputRef = useRef<HTMLInputElement | null>(null);
	const EventService = useMemo(() => getAngularModule('EventService'), []);
	const accountSelf = getAccountSelf();
	const [ticketDeleted, setTicketDeleted] = useState(false);
	const [titleEdit, setTitleEdit] = useState(false);
	const [newTitle, setNewTitle] = useState('');
	const self = getSelf();
	const isSupportUser = self?.support;
	const isAdminUser = self?.administrator;
	const unassigned = !ticket?.user;
	const TITLE_MAX_LENGTH = 150;

	const addCommentToTimeline = (comment: Comment) => {
		const event = EventService.create.Comment(comment);
		addEvent(event);
	};

	useEffect(() => {
		const commentAddedListener = Tools.$rootScope.$on(
			'comment.added',
			async (e: unknown, added: Comment & { tooBigForPusher: boolean }) => {
				let comment;
				try {
					comment = (await CommentResource.get(added.id)).data;
				} catch (e) {
					logError(e, 'Failed to get comment for ticket');
				}
				if (ticketId === comment.ticket?.id) {
					addCommentToTimeline(comment);
				}
			}
		);

		const ticketDeletedListener = Tools.$rootScope.$on(
			'ticket.deleted',
			(e: unknown, deleted: Pick<Ticket, 'id'>) => {
				if (ticketId === deleted.id) {
					setTicketDeleted(true);
				}
			}
		);

		const ticketUpdatedListener = Tools.$rootScope.$on('ticket.updated', (e: unknown, updated: Ticket) => {
			if (ticketId === updated.id) {
				setTicket(updated);
			}
		});

		return () => {
			commentAddedListener();
			ticketDeletedListener();
			ticketUpdatedListener();
		};
	}, [events.length]);
	const { customerId } = useSelector((state: RootState) => ({
		customerId: state.App?.customerId
	}));
	const classes = new BemClass('EditTicket', className);
	const { t } = useTranslation();

	const handleSendButtonClick = () => {
		addComment(true);

		if (status?.closed && unassigned) {
			markAsOpen();
			setUserAndSave({ id: self!.id, name: self!.name }).catch(e =>
				logError(e, 'Failed to assign support user and save')
			);
		} else if (status?.closed) {
			markAsOpen();
		} else if (unassigned) {
			setUserAndSave({ id: self!.id, name: self!.name }).catch(e =>
				logError(e, 'Failed to assign support user and save')
			);
		}
		setFileNames({});
		setTicketResponseTemplate(undefined);
	};

	const renderedEvents = useMemo(() => {
		return events
			.sort((a, b) => (b.date as Date).getTime() - (a.date as Date).getTime())
			.map(event => {
				const isFromContact = !event.comment?.user;
				const isPublic = !!event.comment?.isPublic;

				if (event.comment) {
					if (isFromContact) {
						return (
							<Block key={event.id} space="ptxl pbxl pll prl" className={classes.elem('comment').b()}>
								<div className={classes.elem('comment').elem('header').b()}>
									<Text bold>{event.comment.contact?.name ?? event.comment.from ?? email}</Text>
									<Text size="sm">{dateCalendar(event.date as Date, true)}</Text>
								</div>
								<IframeWrapper srcDoc={event.comment.description}></IframeWrapper>
								<Row>
									{event.files?.map(attachment => (
										<MailAttachment
											key={attachment.id}
											attachment={attachment}
											onRemove={undefined}
										/>
									))}
								</Row>
							</Block>
						);
					}
					if (!isFromContact) {
						const user = event.users[0];
						return (
							<Block
								key={event.comment.id}
								space="ptxl pbxl pll prl"
								backgroundColor={!isPublic ? 'yellow-1' : undefined}
								className={classes.elem('comment').b()}
							>
								<Flex direction="row" gap="u2">
									<Flex>
										<Avatar initials={user.name} email={user.email} size="sm" />
									</Flex>

									<Flex direction="column">
										<div className={classes.elem('comment').elem('header').b()}>
											<Text bold>{user.name}</Text>
											<Text size="sm">{dateCalendar(event.date as Date, true)}</Text>
											<Text size="sm">
												{'- '}
												{t(isPublic ? 'ticket.publicReply' : 'ticket.internalNote')}
											</Text>
										</div>
										{isPublic ? (
											<>
												<IframeWrapper srcDoc={event.comment.description}></IframeWrapper>
												<Row>
													{event.files?.map(attachment => (
														<MailAttachment key={attachment.id} attachment={attachment} />
													))}
												</Row>
											</>
										) : (
											<MentionsInput
												value={event.comment.description}
												onChange={() => {}}
												editorProps={{ readOnly: true }}
											/>
										)}
									</Flex>
								</Flex>
							</Block>
						);
					}
				}

				return null;
			});
	}, [events.map(e => e.id).join(',')]);

	useModalClose(
		modalId,
		e => {
			if (hasUnsavedChanges) {
				e.preventDefault();
				const errorMessage = formatErrorMessages(errorMessages);
				openModal('UnsavedChangesAlert', {
					body: t('ticket.form.fixErrorsDescription', { errorMessage }),
					confirmButtonText: t('ticket.form.fixErrors'),
					onClose: async (confirmed?: boolean) => {
						if (confirmed === undefined) {
							return;
						}

						if (confirmed) {
							const firstError = Object.keys(errorMessages).find(key => errorMessages[key]);
							if (firstError) {
								setTimeout(() => {
									const [field] = document.getElementsByName(firstError);
									if (field) {
										field.focus?.({ preventScroll: true });
										field.scrollIntoView({ behavior: 'smooth', block: 'end' });
									}
								}, 100);
							}
						} else {
							close(undefined, true);
						}
					}
				});
			} else if (
				(replyMode === 'public' && publicMessage.length > 0) ||
				(replyMode === 'internal' && comment.length > 0)
			) {
				e.preventDefault();
				openModal('UnsavedChangesAlert', {
					confirmButtonText: t('default.goBack'),
					onClose: async (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							return;
						}
						close(undefined, true);
					}
				});
			}
		},
		[hasUnsavedChanges, publicMessage, comment]
	);

	if (loading || !ticket?.id) {
		return (
			<Drawer className={classes.b()}>
				<DrawerHeader onHide={close}></DrawerHeader>
				<div className={classes.elem('loader').b()}>
					<Loader />
				</div>
			</Drawer>
		);
	}
	const regDateFormatted = dateCalendar(ticket.regDate, true).toString().toLowerCase();
	const canSave = !ticketDeleted && !!email && isSupportUser;

	const chipTitle = () => {
		if (isArchived) {
			return t('ticket.archived');
		} else if (status.closed) {
			return t('support.closed');
		} else if (!ticket.isRead) {
			return t('support.new');
		} else if (ticket.isPending) {
			return t('support.pending');
		} else {
			return t('support.open');
		}
	};
	const chipColor = () => {
		if (isArchived) {
			return 'info';
		} else if (status.closed) {
			return 'success';
		} else if (!ticket.isRead) {
			return 'alert';
		} else if (ticket.isPending) {
			return 'purple';
		} else {
			return 'danger';
		}
	};

	return (
		<Drawer className={classes.b()}>
			<DrawerHeader
				title={t('ticket.supportTicket')}
				subtitle={t('ticket.id', { id })}
				onHide={close}
				icon="customer-support"
			>
				<Block space="mll">
					<Row align="center">
						<AssistChip title={chipTitle()} type={chipColor()} />
					</Row>
				</Block>
				{ticketDeleted ? (
					<Block space="mll">
						<Row align="center">
							<AssistChip type="danger" title={t('ticket.deleted')} icon="exclamation-circle" />
						</Row>
					</Block>
				) : null}
				<SlideFade visible={showSaveBanner} direction="right">
					<AssistChip
						type="success"
						title={t('ticket.ticketSaved')}
						icon="check"
						className={classes.elem('saved-banner').b()}
					/>
				</SlideFade>
				{isAdminUser || isSupportUser ? (
					<DropDownMenu
						className={classes.elem('header-actions').b()}
						align="right"
						renderTrigger={(expanded, setExpanded) => (
							<ClickableItem onClick={setExpanded} icon="ellipsis-h" borderRadius size="sm" />
						)}
					>
						{closeDropDown => (
							<div className={classes.elem('dropdown-actions').b()}>
								<>
									<ClickableItem
										block
										icon="archive"
										title={t(isArchived ? 'ticket.unarchiveButton' : 'ticket.archiveButton')}
										disabled={!hasCustomerSupport}
										onClick={e => {
											e.stopPropagation();
											onTicketChange(
												{ ...ticket, isArchived: !isArchived },
												isValid,
												errorMessages
											);
											closeDropDown();
										}}
									/>
									{isAdminUser ? (
										<InlineConfirm
											show
											keepTabPosition
											onConfirm={() => deleteTicket().then(() => close())}
										>
											<ClickableItem
												block
												icon="trash"
												title={t('ticket.deleteButton')}
												disabled={!hasCustomerSupport}
											/>
										</InlineConfirm>
									) : null}
								</>
							</div>
						)}
					</DropDownMenu>
				) : null}
			</DrawerHeader>

			<FormObserver<TicketForm>
				validateOnMount
				onChange={(values, isValid, errorMessages) => {
					const updatedTicket = mapFormToTicket(values);
					onTicketChange(updatedTicket, isValid, errorMessages);
				}}
				model={validationModel}
				initialValues={initialValues}
				resetStateIfValueChange={formStateResetTrigger}
			>
				{({ onFormChange, inputProps, isValid, values, errors }) => (
					<div className={classes.elem('content').b()}>
						<div className={classes.elem('content').elem('ticketContent').b()}>
							<Flex justifyContent={'space-between'} alignItems="center" gap="u3">
								{titleEdit ? (
									<>
										<FormInput
											className={classes.elem('titleInput').b()}
											label={t('ticket.title')}
											inputProps={{
												placeholder: t('ticket.enterTitle'),
												inputRef: titleInputRef,
												required: true,
												state: undefined,
												value: newTitle,
												maxLength: TITLE_MAX_LENGTH,
												onChange: e => setNewTitle(e.target.value.toString()),
												label: t('ticket.title'),
												name: 'title'
											}}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													if (newTitle?.length < 1) {
														return;
													} else {
														setTitleEdit(false);
														onFormChange('title', newTitle);
													}
												}
											}}
										/>
										<Flex justifyContent="flex-start" gap="u1" space="mts">
											<PrimaryButton
												disabled={newTitle?.length < 1}
												onClick={() => {
													onFormChange('title', newTitle);
													setTitleEdit(false);
												}}
											>
												<Icon name="check" />
											</PrimaryButton>
											<ThirdButton onClick={() => setTitleEdit(false)}>
												<Icon name="times" />
											</ThirdButton>
										</Flex>
									</>
								) : (
									<>
										<Title size="lg">{title || t('ticket.missingTitle')}</Title>
										<Tooltip position="top" title={t('ticket.editName')}>
											<ClickableItem
												borderRadius
												icon="edit"
												onClick={() => {
													setTitleEdit(prevState => !prevState);
													setNewTitle(ticket.title);
												}}
											/>
										</Tooltip>
									</>
								)}
							</Flex>
							{ticket.source === 'email' ? (
								<Flex alignItems="center" gap="u1">
									<Text>{`${t('ticket.submittedBy')} `}</Text>
									{contact ? (
										<div>
											<EllipsisTooltip title={contact?.name}>
												{/*idk why I have to wrap it in a Text component, but that is how it is done in other places */}
												<Text
													className={classes
														.elem('content')
														.elem('ticketContent')
														.elem('name')
														.b()}
												>
													<Link
														onClick={e => {
															e.stopPropagation();
															window.open(
																Tools.$state.href('contact.dashboard', {
																	id: contact.id,
																	customerId
																})
															);
														}}
														target="_blank"
													>
														{contact?.name}
													</Link>
												</Text>
											</EllipsisTooltip>
										</div>
									) : (
										<>{email}</>
									)}
									<Text>{` ${t('ticket.at')} `}</Text>
									{client ? (
										<div>
											<EllipsisTooltip title={client?.name}>
												<Text
													className={classes
														.elem('content')
														.elem('ticketContent')
														.elem('client')
														.b()}
												>
													<Link
														onClick={e => {
															e.stopPropagation();
															window.open(
																Tools.$state.href('account.dashboard', {
																	id: client.id,
																	customerId
																})
															);
														}}
														target={'_blank'}
													>
														{client?.name}
													</Link>
												</Text>
											</EllipsisTooltip>
										</div>
									) : null}
									<Text>{` ${regDateFormatted} ${t('support.viaEmail').toLowerCase()}`}</Text>
								</Flex>
							) : (
								<Text>
									{t('ticket.createdBy', {
										date: regDateFormatted,
										name: ticket.regBy?.name || ''
									})}
								</Text>
							)}

							<ButtonSelect
								value={replyMode}
								onChange={v => {
									setReplyMode(v);
								}}
								options={[
									{
										title: t('ticket.publicReply'),
										value: 'public'
									},
									{
										title: t('ticket.internalNotes'),
										value: 'internal'
									}
								]}
								disabled={!hasCustomerSupport}
							/>

							<Block space="mtm mbs">
								{replyMode === 'public' ? (
									<>
										<MailEditor
											closed={status?.closed}
											contactName={contact?.name}
											disableEditing={ticketDeleted || !hasCustomerSupport}
											onLeave={close}
											fileNames={fileNames}
											setFileNames={setFileNames}
											ticketResponseTemplate={ticketResponseTemplate}
											setTicketResponseTemplate={setTicketResponseTemplate}
											newTicket={false}
										/>

										<Flex wrap="wrap">
											{files?.map((attachment, idx) => (
												<MailAttachment
													attachment={attachment}
													key={idx}
													onRemove={a => removeFile(a as State['files'][0])}
													clickable={false}
												/>
											))}
										</Flex>
									</>
								) : replyMode === 'internal' ? (
									<MentionsInput
										onChange={setComment}
										value={comment}
										editorRef={r => {
											textareaRef.current = r;
										}}
										editorProps={{
											placeholder: t('ticket.typeYourInternalMessageHere', {
												clientName: accountSelf?.client.name
											}),
											keyBindingFn: e => {
												return undefined;
											}
										}}
										className={classes.elem('mention-input').b()}
									/>
								) : null}
							</Block>

							<div className={classes.elem('content').elem('ticketContent').elem('actions').b()}>
								<Tooltip disabled={isValid} title={formatErrorMessages(errorMessages)}>
									{replyMode === 'internal' ? (
										<PrimaryButton
											disabled={!isValid || !comment.trim().length}
											loading={savingComment}
											onClick={() => addComment(false)}
										>
											{t('ticket.postInternalReply')}
										</PrimaryButton>
									) : (
										<PrimaryButton
											loading={savingComment}
											onClick={() => {
												handleSendButtonClick();
											}}
											disabled={!isValid || !publicMessage || !email}
										>
											{status?.closed
												? t('ticket.postPublicReplyAndOpen')
												: t('ticket.postPublicReply')}
										</PrimaryButton>
									)}
								</Tooltip>

								<Tooltip disabled={isValid} title={formatErrorMessages(errorMessages)}>
									{status?.closed ? (
										<DefaultButton
											onClick={() => {
												markAsOpen();
											}}
											disabled={!hasCustomerSupport}
										>
											<Icon name="check" space="prs" />
											{t('ticket.markAsOpen')}
										</DefaultButton>
									) : (
										<DefaultButton
											onClick={() => {
												markAsSolved(replyMode === 'public' ? publicMessage : undefined);
												setTimeout(() => {
													close(undefined, true);
												}, 2000);
											}}
											loading={saving && canSave}
											disabled={!isSupportUser || !isValid || !hasCustomerSupport}
										>
											<Icon name="check" space="prs" />
											{(publicMessage?.length ?? 0) > 0 && replyMode === 'public'
												? t('ticket.markAsSolvedAndSendReply')
												: t('ticket.markAsSolved')}
										</DefaultButton>
									)}
								</Tooltip>
							</div>

							<Title>{t('ticket.conversation')}</Title>
							<Block space="mbm">
								<Tabs selected={filter} onChange={v => setFilter(v as typeof filter)}>
									<Tab id="all">{t('ticket.all')}</Tab>
									<Tab id="public">{t('ticket.public')}</Tab>
									<Tab id="internal">{t('ticket.internal')}</Tab>
								</Tabs>
							</Block>

							{renderedEvents.length > 0 ? (
								<>
									{renderedEvents}
									{hasMoreEvents ? (
										<Block space="ptxl">
											<Row align="center">
												<Link
													onClick={() => {
														getMoreEvents();
													}}
												>
													{t('default.getMore')}
												</Link>
											</Row>
										</Block>
									) : null}
								</>
							) : (
								<Block space="ptxl">
									<Row align="center">
										<Text italic color="grey-11">
											{t('ticket.noComments')}
										</Text>
									</Row>
								</Block>
							)}
						</div>
						<TicketInfo
							onFormChange={onFormChange}
							formInputProps={inputProps}
							values={values}
							errors={errors}
						/>

						<ClosedTicketScreen />
					</div>
				)}
			</FormObserver>
		</Drawer>
	);
};

export default EditTicket;
