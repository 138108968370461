import React, { useMemo, useState } from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import {
	Block,
	Button,
	Flex,
	FullscreenModal,
	Input,
	Label,
	ModalContent,
	ColorSwitcher,
	ModalHeader,
	Tab,
	Tabs,
	Text,
	Title,
	Tooltip,
	Icon
} from '@upsales/components';
import { PrimaryButton, ThirdButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import MailEditorPlainText from 'Components/MailEditorPlainText/MailEditorPlainText';
import './EditTicketResponseTemplate.scss';
import EditorHeaderButton from 'Components/EditorHeader/EditorHeaderButton';
import { circle } from 'Components/Helpers/styleHelper';
import MailEditorReviewAttachments from 'Components/MailEditorReviewAttachments';
import MailEditorReviewHeader from 'Components/MailEditorReviewHeader';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import TicketResponseTemplateResource from 'App/resources/TicketResponseTemplate';
import logError from 'Helpers/logError';
import TicketResponseTemplate, { MinimalTicketResponseTemplate } from 'App/resources/Model/TicketResponseTemplate';
import File from 'App/babel/resources/File';
import { replaceItem } from 'Store/helpers/array';
import LZString from 'lz-string';
import { ModalProps, useModalClose } from 'App/components/Modals/Modals';
import openModal from 'App/services/Modal';
import { MAX_SIZE } from 'Store/actions/helpers/sharedMailActions';
import { useAppDispatch } from 'App/components/hooks';
import { addNotification, STYLE as notificationStyle } from 'Store/reducers/SystemNotificationReducer';

enum Stages {
	'create',
	'edit'
}

const getHash = (template: MinimalTicketResponseTemplate) => {
	const compareObj = {
		name: template.name || '',
		// ck editor adds a newline at the end regardless of what is changed
		body: template.body.replace(/\n$/, '') || '',
		private: template.private || false,
		attachments: template.attachments || []
	};
	return LZString.compressToBase64(JSON.stringify(compareObj));
};

type Props = {
	ticketResponseTemplate?: TicketResponseTemplate;
};

const EditTicketResponseTemplate = ({ close, className, modalId, ticketResponseTemplate }: Props & ModalProps) => {
	const classes = new bemClass('EditTicketResponseTemplate', className);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const lang = {
		createTitle: t('ticket.template.create.title'),
		createDescription: t('ticket.template.create.description'),
		templateName: t('ticket.template.name'),
		templateNamePlaceholder: t('ticket.template.name.placeholder'),
		createButtonText: t('ticket.template.createButtonText'),
		cancel: t('default.cancel'),
		characterLimitReached: t('default.characterLimitReached'),
		settings: t('admin.settings'),
		design: t('form.design'),
		save: t('default.save'),
		nextStep: t('default.nextStep'),
		reviewAndSave: t('ticket.template.reviewAndSave'),
		reviewAndSaveDescription: t('ticket.template.reviewAndSave.description'),
		saveAndExit: t('mail.saveAndExit'),
		fileSizeTitle: t('default.fileSizeTooLarge'),
		fileSizeBody: t('groupMail.fileLimit'),
		fileErrorTitle: t('mail.uploadFail'),
		fileErrorBody: t('file.uploadFailed'),
		missingName: t('ticket.template.missingName'),
		missingBody: t('ticket.template.missingBody'),
		addText: t('ticket.template.addText')
	};
	const tabs = [
		{ id: 'design', lang: lang.design },
		{ id: 'settings', lang: lang.settings }
	];
	const getNewTicketResponseTemplate = (): MinimalTicketResponseTemplate => ({
		name: '',
		body: lang.addText,
		private: false,
		attachments: []
	});
	const [selectedTab, setSelectedTab] = useState('design');
	const [stage, setStage] = useState(ticketResponseTemplate ? Stages.edit : Stages.create);
	const [template, setTemplate] = useState(ticketResponseTemplate ?? getNewTicketResponseTemplate);
	const [saving, setSaving] = useState(false);
	const sizeOfAllFiles = useMemo(
		() => template.attachments.reduce((total, file) => total + file.size, 0),
		[template.attachments]
	);
	const initialHash = useMemo(
		() => (ticketResponseTemplate ? getHash(ticketResponseTemplate) : getHash(getNewTicketResponseTemplate())),
		[]
	);

	const templateErrors = useMemo(() => {
		const errors: { [key: string]: string } = {};
		if (!template.name.replace(/\s/g, '').length) {
			errors.name = lang.missingName;
		}
		if (!template.body.replace(/\s/g, '').length) {
			errors.body = lang.missingBody;
		}
		return errors;
	}, [template]);
	const hasErrors = Object.keys(templateErrors).length > 0;

	const updateResponseTemplateRequest = React.useRef<null | CancelablePromise<
		Awaited<ReturnType<typeof TicketResponseTemplateResource.save>>
	>>(null);

	const setName = (name: string) => {
		setTemplate(t => ({ ...t, name }));
	};

	const setIsPrivate = (isPrivate: boolean) => {
		setTemplate(t => ({ ...t, private: isPrivate }));
	};

	const setBody = (body: string) => {
		setTemplate(t => ({ ...t, body }));
	};

	const addAttachments = (attachments: any[]) => {
		setTemplate(t => ({ ...t, attachments: [...t.attachments, ...attachments] }));
	};

	const removeAttachment = (attachment: any) => {
		setTemplate(t => ({
			...t,
			attachments: t.attachments.filter(
				a => !(a.filename === attachment.filename && a.value === attachment.value)
			)
		}));
	};

	const save = () => {
		setSaving(true);
		updateResponseTemplateRequest.current = makeCancelable(TicketResponseTemplateResource.save(template));
		updateResponseTemplateRequest.current.promise
			.then(res => {
				close();
			})
			.catch(e => logError(e, 'Failed to save ticket response template'))
			.finally(() => setSaving(false));
		return updateResponseTemplateRequest.current.promise;
	};

	const updateFileProgress = (filename: string, progress: number, data?: any, error = false) => {
		setTemplate(t => {
			const i = t.attachments.findIndex(f => filename === f.filename && f.pending === true);
			let newFile;
			if (progress === 100 && data) {
				newFile = { filename, size: data.size, value: data.id, type: 'InternalFile', pending: false };
			} else {
				newFile = { ...t.attachments[i], progress, error };
			}
			return { ...t, attachments: replaceItem(t.attachments, i, newFile) };
		});
	};

	const uploadFiles = async (files: File[]) => {
		const totalSize = files.reduce((total, file) => total + file.size, 0);
		if (totalSize + sizeOfAllFiles > MAX_SIZE) {
			dispatch(
				addNotification({
					style: notificationStyle.WARN,
					icon: 'warning',
					title: lang.fileSizeTitle,
					body: lang.fileSizeBody
				})
			);
			return;
		}

		const pendingFiles = files.map(file => {
			return { file, filename: file.name, pending: true, error: false, progress: 0, size: file.size };
		});
		addAttachments(pendingFiles);

		for (let i = pendingFiles.length - 1; i >= 0; i--) {
			try {
				const data = await File.upload(pendingFiles[i].file, {
					onUploadProgress: (progress: number) => {
						updateFileProgress(pendingFiles[i].filename, progress);
					},
					fileEntity: 'mail'
				});
				updateFileProgress(pendingFiles[i].filename, 100, data);
			} catch (e) {
				dispatch(
					addNotification({
						style: notificationStyle.ERROR,
						icon: 'times',
						title: lang.fileErrorTitle,
						body: lang.fileErrorBody
					})
				);
				removeAttachment(pendingFiles[i]);
			}
		}
	};

	useModalClose(
		modalId,
		event => {
			if (stage === Stages.edit && initialHash !== getHash(template) && !saving) {
				event.preventDefault();
				openModal('UnsavedChangesAlert', {
					confirmButtonText: t('default.goBack'),
					onClose: async (confirmed?: boolean) => {
						if (confirmed || confirmed === undefined) {
							return;
						}

						close(undefined, true);
					}
				});
			}
		},
		[template, stage, saving]
	);

	return (
		<FullscreenModal className={classes.mod({ edit: stage === Stages.edit }).b()} headerAtTop>
			{stage === Stages.create ? (
				<Button className={'FullScreenModal__close'} onClick={() => close()} shadow="none">
					<Icon name="times" />
				</Button>
			) : (
				<ModalHeader title={template.name} alignContent="left">
					<Tabs noFlex color="white" onChange={setSelectedTab} selected={selectedTab}>
						{tabs.map((tab, i) => (
							<Tab key={tab.id} id={tab.id}>
								<Flex>
									<ColorSwitcher style={circle()}>{i + 1}</ColorSwitcher>
									<Text space="mlm" color="inherit" bold={selectedTab === tab.id}>
										{tab.lang}
									</Text>
								</Flex>
							</Tab>
						))}
					</Tabs>
					<Block className={classes.elem('controls').b()}>
						<EditorHeaderButton
							title={lang.cancel}
							onClick={close}
							supertitle={undefined}
							className={classes.elem('cancel').b()}
							noIcon
							next={false}
						/>
						<Tooltip title={Object.values(templateErrors).join('\n')} disabled={!hasErrors}>
							<EditorHeaderButton
								title={selectedTab === 'settings' ? lang.saveAndExit : lang.settings}
								supertitle={selectedTab === 'settings' ? null : lang.nextStep}
								disabled={selectedTab === 'settings' && hasErrors}
								onClick={selectedTab === 'settings' ? () => save() : () => setSelectedTab('settings')}
								next
								className={undefined}
								noIcon={false}
								icon={selectedTab === 'settings' ? 'check' : undefined}
							/>
						</Tooltip>
					</Block>
				</ModalHeader>
			)}

			<ModalContent>
				{stage === Stages.create ? (
					<Flex direction="column" gap="u6">
						<Block>
							<Title size="lg" bold>
								{lang.createTitle}
							</Title>
							<Text color="grey-10" space="pts">
								{lang.createDescription}
							</Text>
						</Block>
						<Block>
							<Label
								required
								value={template.name}
								maxLength={256}
								maxLengthReachedText={lang.characterLimitReached}
							>
								{lang.templateName}
							</Label>
							<Input
								value={template.name}
								maxLength={256}
								onChange={e => setName(e.target.value)}
								placeholder={lang.templateNamePlaceholder}
							/>
						</Block>
						<PrimaryButton disabled={!template.name?.length} onClick={() => setStage(Stages.edit)}>
							{lang.createButtonText}
						</PrimaryButton>
						<ThirdButton onClick={() => close()}>{lang.cancel}</ThirdButton>
					</Flex>
				) : selectedTab === 'design' ? (
					<Flex>
						<MailEditorPlainText
							value={template.body}
							onChange={(e: { value: string }) => setBody(e.value)}
							isSocialEvent={false}
							isWebinar={false}
							tagEntity="ticket"
						/>
					</Flex>
				) : (
					<Flex direction="column">
						<MailEditorReviewHeader
							title={lang.reviewAndSave}
							label={lang.templateName}
							text={lang.reviewAndSaveDescription}
							isPrivate={template.private}
							onPrivateChange={setIsPrivate}
							value={template.name}
							onChangeBounced={setName}
						/>
						<MailEditorReviewAttachments
							uploadFiles={uploadFiles}
							attachments={template.attachments}
							onRemoveAttachment={removeAttachment}
						/>
					</Flex>
				)}
			</ModalContent>
		</FullscreenModal>
	);
};

export default EditTicketResponseTemplate;
