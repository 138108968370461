import { Action } from '../SubscriptionIndexingState/SubscriptionIndexingActions';
import SubscriptionIndexingState, { State } from '../SubscriptionIndexingState/SubscriptionIndexingState';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import { Block, Input, Row, Tab, Tabs, Title } from '@upsales/components';
import SubscriptionList, { AgreementProcessed } from '../SubscriptionList/SubscriptionList';
import RequestBuilder from 'Resources/RequestBuilder';
import ListViewFilters from 'App/components/ListView/ListViewFilters';
import { getFilter } from '../SubscriptionIndexingState/SubscriptionIndexingReducer';
import { RenderHeaderProvided } from 'App/components/ListView/ListViewRenderHelpers';
import AgreementResource from 'Resources/Agreement';
import React, { useCallback, useMemo } from 'react';
import moment from 'moment';

import './SubscriptionIndexingPreview.scss';

type Props = {
	state: State;
	dispatch: React.Dispatch<Action>;
};

const SubscriptionIndexingPreview = (props: Props) => {
	const { t } = useTranslation();
	const { state, dispatch } = props;
	const classNames = new BemClass('SubscriptionIndexingPreview');
	const [selectedTab, setSelectedTab] = React.useState<'selected' | 'excluded'>('selected');

	const showExcluded = useMemo(() => selectedTab === 'excluded', [selectedTab]);

	const { subscriptionCount, indexInterval, isLocked, excludedSubscriptionIds, specificDate, uiFilters, filter } =
		state;
	const { excludeSubscriptionId, includeSubscriptionId, setUiFilters, setFilterQ } =
		SubscriptionIndexingState(dispatch);

	const getData = useCallback(
		(rb: RequestBuilder, { filters }) => {
			if (isLocked) {
				return AgreementResource.find(filter);
			}
			const { q, ...rest } = rb.build();
			const filterQ = [...(q ?? [])];

			setUiFilters(filters);
			setFilterQ(filterQ);

			const builtFilter = showExcluded
				? {
						q: [
							{
								a: 'id',
								c: 'eq',
								v: excludedSubscriptionIds
							}
						]
				  }
				: getFilter(filterQ, excludedSubscriptionIds, indexInterval);

			return AgreementResource.find({
				...builtFilter,
				...rest
			});
		},
		[showExcluded, excludedSubscriptionIds, indexInterval, filter, isLocked]
	);

	const renderHeader = useCallback(
		(provided: RenderHeaderProvided<AgreementProcessed>) => (
			<Row key={'header'} align="space-between" className={classNames.elem('header').b()}>
				<Tabs
					align="left"
					selected={selectedTab}
					onChange={value => {
						setSelectedTab(value as 'selected' | 'excluded');
						provided.setFiltersVisible(value === 'selected' && !isLocked);
						setTimeout(() => provided.reloadTable({ silent: true }));
					}}
				>
					<Tab
						id="selected"
						title={t('subscription.indexing.selection.tab.subscriptions')}
						subtitle={(subscriptionCount ?? 0).toString()}
					/>
					<Tab
						id="excluded"
						title={t('subscription.indexing.selection.tab.excluded')}
						subtitle={excludedSubscriptionIds.length.toString()}
					/>
				</Tabs>

				<Block space="ptl">
					<Row>
						{isLocked || selectedTab === 'excluded' ? null : (
							<>
								<Block space="mrl">
									<Input color="grey-2" noborder {...provided.quickSearchProps} />
								</Block>
								<ListViewFilters
									onVisibleChange={provided.setFiltersVisible}
									filterConfigs={provided.filterConfigs!}
									activeFilters={provided.filters}
									onChange={provided.onFilterChange}
									hiddenFilters={provided.hiddenFilters}
									hasChanged={provided.hasChanged}
									listType={'agreement'}
									customFields={provided.customFields.filter(f => f.nameType === 'Agreement')}
									opts={provided.opts}
								/>
							</>
						)}
					</Row>
				</Block>
			</Row>
		),
		[isLocked, selectedTab, subscriptionCount, excludedSubscriptionIds.length]
	);

	const columns = useMemo(
		() => [
			'info',
			'periodLength',
			'lastIndexIncreaseDate',
			'increasedPeriodValue',
			showExcluded ? 'include' : 'exclude'
		],
		[showExcluded]
	);

	const title =
		indexInterval === 'once'
			? t('subscription.indexing.previewPage.title.specificDate', { date: moment(specificDate).format('L') })
			: t('subscription.indexing.previewPage.title.renewal');

	return (
		<Row direction="column">
			<Block space="pbl" />
			<Block space="ptxl pbl pll">
				<Title>{title}</Title>
			</Block>
			<Block space="ptxl">
				<SubscriptionList
					fullWidth
					getData={getData}
					columns={columns}
					state={state}
					includeOrExcludeSub={(type, id) =>
						type === 'exclude' ? excludeSubscriptionId(id) : includeSubscriptionId(id)
					}
					renderHeader={renderHeader}
					initialFilters={uiFilters}
				/>
			</Block>
		</Row>
	);
};

export default SubscriptionIndexingPreview;
